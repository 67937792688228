import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'succStat',
})
export class SuccStatPipe implements PipeTransform {
  transform(value: unknown): unknown {
    let content = '';
    if (!Number(value)) {
      content = '成功';
    } else {
      content = '失败';
    }
    return content;
  }
}
