import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'stopReason'
})
export class StopReasonPipe implements PipeTransform {

  transform(value: unknown, ...args: unknown[]): unknown {
    let content = '';
    if (Number(value) === 0) {
      content = '用户手动停止充电';
    }
    if (Number(value) === 1) {
      content = '客户归属地运营商平台停止充电';
    }
    if (Number(value) === 2) {
      content = 'BMS停止充电';
    }
    if (Number(value) === 3) {
      content = '充电机设备故障';
    }
    if (Number(value) === 4) {
      content = '连接器断开';
    }
    if (Number(value) === 5) {
      content = '~99自定义';
    }
    return content;
  }

}
