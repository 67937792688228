import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app.routing';
import { AppComponent } from './app.component';
import { NZ_I18N } from 'ng-zorro-antd/i18n';
import { zh_CN } from 'ng-zorro-antd/i18n';
import { registerLocaleData } from '@angular/common';
import zh from '@angular/common/locales/zh';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { DelonAuthModule, SimpleInterceptor } from '@delon/auth';
import { DelonCacheModule } from '@delon/cache';
import { BackInterceptor } from './core/back.service';
import { AlainConfig, ALAIN_CONFIG } from '@delon/util';
import { NzConfig, NZ_CONFIG } from 'ng-zorro-antd/core/config';
import { SharedModule } from './shared/shared.module';
const alainConfig: AlainConfig = {
  auth: { token_send_key: 'Authorization', login_url: '/passport/login' },
};

registerLocaleData(zh);
const ngZorroConfig: NzConfig = {
  notification: { nzMaxStack: 1 },
};

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    DelonAuthModule,
    DelonCacheModule,
    SharedModule
  ],
  providers: [
    { provide: NZ_I18N, useValue: zh_CN },
    { provide: NZ_CONFIG, useValue: ngZorroConfig },
    { provide: ALAIN_CONFIG, useValue: alainConfig },
    { provide: HTTP_INTERCEPTORS, useClass: BackInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: SimpleInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
