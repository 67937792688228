import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SunrayHttpService {
  public err = new Subject();
  public menu = new Subject();
  public isCollapsed = new Subject();
  constructor(private http: HttpClient) {}
  /**
   *
   * @param url 通用GET请求（url:请求地址）
   * @param options 可选配置项
   *
   */
  get(url: string, options?: any): Observable<any> {
    return this.http.get(`/api/${url}`, options);
  }
  /**
   *
   * @param url 通用POST请求（url:请求地址）
   * @param body 参数主体
   * @param options 可选配置项
   *
   */
  post(url: string, body: any | null, options?: any): Observable<any> {
    return this.http.post(`/api/${url}`, body, options);
  }
  /**
   * @param url 通用DELETE请求（url:请求地址）
   * @param options 可选配置项
   */
  delete(url: string, options?: any): Observable<any> {
    return this.http.delete(`/api/${url}`, options);
  }
  /**
   *
   * @param url 通用PUT请求（url:请求地址）
   * @param body 参数主体
   * @param options 可选配置项
   */
  put(url: string, body: any | null, options?: any): Observable<any> {
    return this.http.put(`/api/${url}`, body, options);
  }
  /**导出 */
  export(params: any, url: string): Observable<any> {
    return this.http.get(url, {
      params,
      observe: 'response',
      responseType: 'blob',
    });
  }
  geography(): Observable<any> {
    return this.http.get('assets/datas.json');
  }
}
