import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginGuardService } from './core/login.service';
const canActivate = [LoginGuardService];
const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: 'porsche' },
  /**布局 */
  {
    path: 'porsche',
    loadChildren: () =>
      import('./layout/layout.module').then((p) => p.LayoutModule),
    canActivate,
  },
  /**登录 */
  {
    path: 'passport',
    loadChildren: () =>
      import('./pages/passport/passport.module').then((p) => p.PassportModule),
  },
  {
    path:'test',
    loadChildren:()=>import('./test/test.module').then((t)=>t.TestModule),
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
