import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'connectorStatus',
})
export class ConnectorStatusPipe implements PipeTransform {
  transform(value: unknown, ...args: unknown[]): unknown {
    let content = '';
    if (Number(value) === 1) {
      content = 'Available可用';
    }
    if (Number(value) === 2) {
      content = 'Preparing 充电准备中';
    }
    if (Number(value) === 3) {
      content = 'Charging 充电中';
    }
    if (Number(value) === 4) {
      content = 'Finish 充电结束';
    }
    if (Number(value) === 0) {
      content = 'Un-availabl不可用（离线）';
    }
    return content;
  }
}
