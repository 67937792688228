import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NzNotificationModule } from 'ng-zorro-antd/notification';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzMenuModule } from 'ng-zorro-antd/menu';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzPaginationModule } from 'ng-zorro-antd/pagination';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzSpaceModule } from 'ng-zorro-antd/space';
import { NzTreeModule } from 'ng-zorro-antd/tree';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { NzSwitchModule } from 'ng-zorro-antd/switch';
import { NzUploadModule } from 'ng-zorro-antd/upload';
import { NzCascaderModule } from 'ng-zorro-antd/cascader';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzInputNumberModule } from 'ng-zorro-antd/input-number';
import { IconsProviderModule } from '../icons-provider.module';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { NzAlertModule } from 'ng-zorro-antd/alert';
import { SuccStatPipe } from './pipes/succ-stat.pipe';
import { StartChargeSeqStatPipe } from './pipes/start-charge-seq-stat.pipe';
import { ConnectorStatusPipe } from './pipes/connector-status.pipe';
import { StopReasonPipe } from './pipes/stop-reason.pipe';
import { SupportOrderPipe } from './pipes/support-order.pipe';
import { NzStepsModule } from 'ng-zorro-antd/steps';
import { NzProgressModule } from 'ng-zorro-antd/progress';
import { NzStatisticModule } from 'ng-zorro-antd/statistic';
import { NzPopoverModule } from 'ng-zorro-antd/popover';


const NGZORRO = [
  NzButtonModule,
  NzFormModule,
  NzNotificationModule,
  NzInputModule,
  NzDropDownModule,
  NzLayoutModule,
  NzMenuModule,
  IconsProviderModule,
  NzTabsModule,
  NzFormModule,
  NzInputModule,
  NzButtonModule,
  NzDropDownModule,
  NzNotificationModule,
  NzTableModule,
  NzPaginationModule,
  NzModalModule,
  NzSpinModule,
  NzCheckboxModule,
  NzDividerModule,
  NzSpaceModule,
  NzTreeModule,
  NzSelectModule,
  NzInputNumberModule,
  NzRadioModule,
  NzSwitchModule,
  NzCascaderModule,
  NzUploadModule,
  NzDatePickerModule,
  NzAlertModule,
  NzStepsModule,
  NzProgressModule,
  NzStatisticModule,
  NzPopoverModule
];
const APP = [CommonModule, FormsModule, ReactiveFormsModule];
const PIPES = [
  SuccStatPipe,
  StartChargeSeqStatPipe,
  ConnectorStatusPipe,
  StopReasonPipe,
  SupportOrderPipe,
];
@NgModule({
  declarations: [...PIPES],
  imports: [...APP, ...NGZORRO],
  exports: [...APP, ...PIPES, ...NGZORRO],
})
export class SharedModule {}
