import { Injectable, Injector } from '@angular/core';
import {
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpResponse,
  HttpResponseBase,
  HttpErrorResponse,
} from '@angular/common/http';
import { mergeMap, catchError } from 'rxjs/operators';
import { Observable } from 'rxjs/internal/Observable';
import { throwError, of } from 'rxjs';
import { Router } from '@angular/router';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { SunrayHttpService } from './sunray-http.service';
const CODEMESSAGE: any = {
  200: '服务器成功返回请求的数据。',
  201: '新建或编辑数据成功。',
  202: '一个请求已经进入后台排队（异步任务）。',
  204: '删除数据成功。',
  400: '发出的请求有错误，服务器没有进行新建或编辑数据的操作。',
  401: '用户没有权限（令牌、用户名、密码错误）。',
  403: '用户得到授权，但是访问是被禁止的。',
  404: '发出的请求针对的是不存在的记录，服务器没有进行操作。',
  406: '请求的格式不可得。',
  410: '请求的资源被永久删除，且不会再得到的。',
  422: '当创建一个对象时，发生一个验证错误。',
  500: '服务器发生错误，请检查服务器。',
  502: '网关错误。',
  503: '服务不可用，服务器暂时过载或维护。',
  504: '网关超时。',
};
@Injectable()
export class BackInterceptor implements HttpInterceptor {
  constructor(
    private injector: Injector,
    private notification: NzNotificationService,
    private sunrayhttp: SunrayHttpService
  ) {}
  private goTo(url: string) {
    setTimeout(() => this.injector.get(Router).navigateByUrl(url));
  }

  private checkStatus(ev: HttpResponseBase) {
    if ((ev.status >= 200 && ev.status < 300) || ev.status === 400) {
      return;
    }
    const errortext = CODEMESSAGE[ev.status] || ev.statusText;
    this.notification.blank('系统提示', errortext);
  }
  private handleData(ev: HttpResponseBase): Observable<any> {
    this.checkStatus(ev);
    // 业务处理： 服务端返回200，但是有可能是业务上出错
    switch (ev.status) {
      case 200:
        if (ev instanceof HttpResponse) {
          const body: any = ev.body;
          switch (body.status) {
            case 30101:
              this.sunrayhttp.err.next();
              return throwError({
                status: body.status,
                statusText: body.message,
              });
            default:
              break;
          }
        }
        break;
      case 401:
        this.goTo('/passport/login');
        break;
      default:
        if (ev instanceof HttpErrorResponse) {
          // console.warn("未可知错误，大部分是由于后端不支持CORS或无效配置引起");
        }
        break;
    }
    if (ev instanceof HttpErrorResponse) {
      return throwError(ev);
    } else {
      return of(ev);
    }
  }

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    return next.handle(req).pipe(
      mergeMap((event: any) => {
        // 允许统一对请求错误处理
        if (event instanceof HttpResponseBase) {
          return this.handleData(event);
        }
        // 若一切都正常，则后续操作
        return of(event);
      }),
      catchError((err: HttpErrorResponse) => this.handleData(err))
    );
  }
}
