import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'startChargeSeqStat',
})
export class StartChargeSeqStatPipe implements PipeTransform {
  transform(value: unknown, ...args: unknown[]): unknown {
    let content = '';
    if (Number(value) === 1) {
      content = '启动中';
    }
    if (Number(value) === 2) {

      content = '充电中';
    }
    if (Number(value) === 3) {
      content = '停止中';
    }
    if (Number(value) === 4) {
      content = '已结束';
    }
    if (Number(value) === 5) {
      content = '未知';
    }
    return content;
  }
}
