import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'supportOrder',
})
export class SupportOrderPipe implements PipeTransform {
  transform(value: unknown, ...args: unknown[]): unknown {
    let content = '';
    if (Number(value)) {
      content = '支持';
    } else {
      content = '不支持';
    }
    return content;
  }
}
